import { CONTENT } from '../constants/content';
import { Grommet } from 'grommet';
import Head from 'next/head';
import Layout from '../components/Layout/Layout';
import PropTypes from 'prop-types';
import { getAllPostIds } from '../utils/postProcessor';
import { siteTheme } from '../configs/siteTheme';
export async function getStaticProps() {
  const allPostsData = await getAllPostIds();
  return {
    props: {
      allPostsData,
      fallback: false
    }
  };
}

export default function Home({ allPostsData }) {
  return (
    <Grommet theme={siteTheme} full={true}>
      <Head>
        <title>{CONTENT.TEXT.SITE_TITLE}</title>
        <link rel="site icon" href="/544332.png" />
      </Head>
      <Layout showArticleList={true} allPostsData={allPostsData}></Layout>
    </Grommet>
  );
}

Home.propTypes = {
  allPostsData: PropTypes.array
};
